@import '../../../SCSS/mixins';

.asia {

  & .s_w_asia {
    margin-top: 50px;
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 40px;

    @include mobileL {
      gap: 20px;
    }

    & h2 {
      color: rgb(21, 27, 35);
      @include roboto;
      font-size: 36px;
      font-weight: 600;
      line-height: 140%;
      letter-spacing: 0%;
      text-align: center;
      width: 500px;
    }

    & p {
      color: rgb(156, 156, 156);
      @include PlusJakartaSans;
      font-size: 16px;
      font-weight: 400;
      line-height: 24px;
      letter-spacing: 0%;
      text-align: center;
      width: 700px;
    }

    @include mobileL {
      & h2 {
        width: 100%;
        font-size: 24px;
      }

      & p {
        width: 100%;
        font-size: 14px;
      }
    }
  }
}