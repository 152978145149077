@import '../../SCSS/mixins';

header {
  padding: 5px 0;
  width: 100%;
  background: rgb(255, 255, 255);
  position: fixed;
  top: 0;
  left: 0;
  z-index: 101;
  border-bottom: 1px solid rgb(81, 137, 241);

  @include mobileL {
    padding: 6px 0;
  }

  nav {
    display: flex;
    justify-content: space-between;
    align-items: center;

    .logo {
      width: 92px;
      height: 65px;

      @include mobileL {
        width: 70px;
        height: 50px;
      }
    }

    .menuBox {
      display: flex;
      align-items: center;
      gap: 180px;

      .menu {
        display: flex;
        justify-content: space-between;
        align-items: end;
        gap: 40px;
        padding: 32px 0;


        .nav_link {
          color: #959595;
          @include PlusJakartaSans;
          font-size: 20px;
          font-weight: 400;
          line-height: 30px;
          letter-spacing: 0%;
          text-align: left;
          cursor: pointer;
        }

        .active_nav {
          color: rgb(81, 137, 241);
          @include PlusJakartaSans;
          font-size: 20px;
          font-weight: 700;
          line-height: 30px;
          letter-spacing: 0%;
          text-align: left;
        }

      }


    }

    .btnnumber {
      border-radius: 56px;
      background: rgb(108, 140, 199);
      display: flex;
      flex-direction: row;
      justify-content: center;
      align-items: center;
      padding: 8px 24px 8px 24px;
      color: rgb(255, 255, 255);
      @include PlusJakartaSans;
      font-size: 18px;
      font-weight: 400;
      line-height: 26px;
    }

    .nav_toogler {
      display: none;
      cursor: pointer;
      z-index: 1001;
    }

    .nav_toogler div {
      width: 1.7rem;
      height: 0.16rem;
      margin: 0.4rem;
      background-color: rgb(0, 0, 0);
      transition: 0.4s ease-in;
      z-index: 1001;
    }

    @include newMonitor1440 {
      .menuBox {
        gap: 140px;
      }
    }

    @include oldMonitor {
      .menuBox {
        gap: 70px;

        .menu {
          gap: 20px;

          .nav_link {
            font-size: 16px;
          }

          .active_nav {
            font-size: 16px;
          }
        }
      }
    }

    @include old1024 {
      .menuBox {
        gap: 15px;

        .menu {
          gap: 15px;

          .nav_link {
            font-size: 14px;
          }

          .active_nav {
            font-size: 14px;
          }
        }
      }

      .btnnumber {
        padding: 2px 10px;
        font-size: 14px;
      }
    }

    @include mobileL {
      .menuBox {
        .menu {
          display: none;
        }
      }

      .btnnumber {
        display: none;
      }

      .nav_toogler {
        display: block;
      }

      .toogle .line1 {
        transform: rotate(-45deg) translate(-2px, 3px);
      }

      .toogle .line2 {
        opacity: 0;
      }

      .toogle .line3 {
        transform: rotate(45deg) translate(-9px, -10px);
      }
    }

    .number_box {
      display: flex;
      align-items: center;
      gap: 50px;

      @include tablet {
        gap: 15px;
      }

      @include mobileL {
        display: none;
      }
    }
  }
}