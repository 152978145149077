@import '../../../SCSS/mixins';

.bgHome {
    height: 900px;
    z-index: 10;
    width: 100%;
    box-sizing: border-box;
    background: center / cover no-repeat;
    position: relative;
    object-position: center;
    display: flex;
    flex-direction: column;
    justify-content: flex-end;
    height: 800px;

    @include oldMonitor {
        height: 700px;

    }

    @include tablet {
        height: 600px;
    }

    @include mobileL {
        height: 400px;
    }

    @include mobileM {
        height: 300px;
    }

    .content {
        display: flex;
        width: 100%;
        flex-direction: column;
        height: 75%;
        gap: 30px;

        .title {
            color: rgb(255, 255, 255);
            @include roboto;
            font-size: 50px;
            font-weight: 600;
            letter-spacing: 0%;
            text-transform: capitalize;
            width: 40%;

            @include oldMonitor {
                font-size: 40px;
            }

            @include tablet {
                font-size: 32px;
            }

            @include mobileL {
                font-size: 22px;
            }

            @include mobileM {
                font-size: 18px;
            }
        }

        .subtitle {
            color: rgb(166, 206, 57);
            @include roboto;
            font-size: 32px;
            font-weight: 600;
            letter-spacing: 10%;
            line-height: 110%;
            text-transform: uppercase;
            width: 100%;
            white-space: break-spaces;

            @include oldMonitor {
                font-size: 28px;
            }

            @include tablet {
                font-size: 22px;
            }

            @include mobileL {
                font-size: 18px;
            }

            @include mobileM {
                font-size: 14px;
            }
        }
    }
}