@import '../../../../SCSS/mixins';

.banner {
  position: relative;
  background-repeat: no-repeat;
  background-size: cover;
  width: 100%;
  margin-bottom: 70px;
  overflow: hidden;

  .background {
    position: absolute;
    width: 100%;
    height: 100%;
    object-fit: cover;
    object-position: center;
    z-index: -1;

    @include mobileL {
      position: relative;
    }
  }

  .text_in_banner {
    padding: 40px 0;

    & h2 {
      color: rgb(255, 255, 255);
      @include roboto;
      font-size: 36px;
      font-weight: 600;
      line-height: 42px;
      letter-spacing: 0%;
      text-align: left;
      margin-bottom: 30px;
      width: 35%;
    }

    & p {
      color: rgb(255, 255, 255);
      @include roboto;
      font-size: 18px;
      font-weight: 400;
      line-height: 21px;
      letter-spacing: 0%;
      text-align: left;
      width: 35%;
      margin-bottom: 30px;
    }

    & button {
      padding: 15px 32px;
      border-radius: 100px;
      box-shadow: 24px 24px 64px 0px rgba(0, 0, 0, 0.1);
      background: rgb(108, 140, 199);
      color: rgb(238, 238, 238);
      @include PlusJakartaSans;
      font-size: 18px;
      font-weight: 600;
      line-height: 26px;
      letter-spacing: 0%;
      text-align: left;
    }

    @include oldMonitor {
      & h2 {
        width: 45%;
      }

      & p {
        width: 45%;
      }
    }

    @include old1024 {
      & h2 {
        width: 55%;
      }

      & p {
        width: 55%;
      }
    }

    @include mobileL {
      padding: 20px 0;
      display: flex;
      flex-direction: column;
      align-items: center;

      & h2 {
        width: 100%;
        text-align: center;
        color: rgb(0, 0, 0);
        font-size: 22px;
      }

      & p {
        width: 100%;
        text-align: center;
        color: rgb(0, 0, 0);
        font-size: 14px;
      }
    }
  }
}