@import '../../../SCSS/mixins';

.WCUBox {
    margin-top: 50px;

    .contentBox {
        display: flex;
        flex-direction: column;
        gap: 40px;

        .topBox {
            display: flex;
            justify-content: space-between;
            align-items: center;
            gap: 40px;

            .leftC {
                display: flex;
                flex-direction: column;
                gap: 60px;

                .title {
                    color: rgb(0, 0, 0);
                    @include roboto;
                    font-size: 36px;
                    font-weight: 600;
                    line-height: 42px;
                    letter-spacing: 0%;
                }

                .itemBox {
                    display: flex;
                    flex-direction: column;
                    gap: 20px;

                    .item {
                        display: flex;
                        justify-content: flex-start;
                        align-items: center;
                        gap: 20px;

                        .img {
                            width: 48px;
                            height: 48px;
                        }

                        .subTitle {
                            color: rgb(0, 0, 0);
                            @include roboto;
                            font-size: 18px;
                            font-weight: 400;
                            line-height: 21px;
                            letter-spacing: 0%;
                        }
                    }
                }
            }

            .rightC {
                width: 500px;
                height: 465px;
                border-radius: 20px;
                overflow: hidden;

                img {
                    width: 100%;
                    height: 100%;
                    border-radius: 20px;
                    object-position: center;
                    object-fit: cover;
                }
            }
        }

        .bottomBox {
            display: flex;
            justify-content: space-between;
            gap: 40px;
            margin-bottom: 60px;

            .imgL {
                width: 100%;
                height: 469px;
                border-radius: 20px;


                img {
                    width: 100%;
                    height: 100%;
                    border-radius: 20px;
                    object-fit: cover;
                    object-position: center;
                }
            }

            .imgR {
                width: 800px;
                height: 469px;
                border-radius: 20px;

                img {
                    width: 100%;
                    height: 100%;
                    border-radius: 20px;
                    object-fit: cover;
                    object-position: center;
                }
            }
        }

        @include oldMonitor {
            gap: 30px;

            .topBox {
                gap: 20px;

                .rightC {
                    width: 400px;
                    height: 400px;
                }
            }

            .bottomBox {
                gap: 20px;

                .imgL {
                    height: 400px;
                }

                .imgR {
                    height: 400px;
                    width: 600px;
                }
            }
        }

        @include tablet {
            gap: 20px;

            .topBox {
                flex-direction: column;

                .leftC {
                    width: 100%;
                    gap: 20px;
                }

                .rightC {
                    width: 100%;
                    height: 350px;
                }
            }

            .bottomBox {
                flex-direction: column;

                .imgL {
                    height: 350px;
                }

                .imgR {
                    width: 100%;
                    height: 350px;
                }
            }
        }

        @include mobileL {
            gap: 20px;

            .topBox {
                flex-direction: column;

                .leftC {
                    width: 100%;
                    gap: 20px;

                    .title {
                        font-size: 28px;
                        text-align: center;
                    }
                }

                .rightC {
                    width: 100%;
                    height: 250px;
                }
            }

            .bottomBox {
                flex-direction: column;

                .imgL {
                    height: 250px;
                }

                .imgR {
                    width: 100%;
                    height: 250px;
                }
            }
        }
    }
}