@import '../../../SCSS/mixins';

.Acordion {
    margin-top: 50px;

    .acorBox {
        display: flex;
        justify-content: space-between;
        gap: 40px;

        .leftContent {
            flex: 0 1 50%;
            display: flex;
            flex-direction: column;
            gap: 30px;

            .title {
                color: rgb(19, 19, 26);
                @include roboto;
                font-size: 48px;
                font-weight: 600;
                line-height: 56px;
                letter-spacing: 0%;
                text-align: left;
            }

            .paragraf {
                color: rgb(156, 156, 156);
                @include PlusJakartaSans;
                font-size: 16px;
                font-weight: 400;
                line-height: 24px;
                letter-spacing: 0%;
                text-align: left;
            }

            .btn {
                width: max-content;
                padding: 16px 32px 16px 32px;
                color: rgb(238, 238, 238);
                @include PlusJakartaSans;
                font-size: 18px;
                font-weight: 600;
                line-height: 26px;
                letter-spacing: 0%;
                border-radius: 100px;
                box-shadow: 24px 24px 64px 0px rgba(0, 0, 0, 0.1);
                background: rgb(108, 140, 199);
            }
        }

        .rightContent {
            flex: 0 1 50%;
        }
    }

    @include oldMonitor {
        .acorBox {
            flex-direction: column;
            gap: 20px;

            .leftContent {
                align-items: center;
                gap: 20px;

                .title {
                    font-size: 40px;
                }
            }
        }
    }

    @include tablet {
        .acorBox {
            .leftContent {
                .title {
                    font-size: 34px;
                    line-height: 40px;
                }

                .btn {
                    padding: 10px 20px;
                    // width: 210px;
                    font-size: 16px;
                }
            }
        }
    }

    @include tablet {
        .acorBox {
            .leftContent {
                .title {
                    font-size: 22px;
                    line-height: 30px;
                }

                .btn {
                    padding: 10px 10px;
                    // width: 210px;
                    font-size: 16px;
                }
            }
        }
    }
}