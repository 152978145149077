@import '../../../SCSS/mixins';

.LanguageBox {
    display: flex;
    gap: 10px;

    .img {
        width: 30px;
        height: 17px;
        object-position: center;
        object-fit: contain;
        cursor: pointer;
    }

    .img_active {
        cursor: pointer;
        width: 30px;
        height: 17px;
        object-position: center;
        object-fit: contain;
        transform: scale(1.3);
    }

    @include mobileL {
        margin-top: 40px;
    }
}