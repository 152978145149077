@import '../../../SCSS/mixins';

.Burger {
    width: 100%;
    height: 100%;
    z-index: 150;
    position: fixed;
    top: 0;
    left: 0;

    @include mobileL {
        display: none;
    }

    .BurgerMenu {
        width: 300px;
        height: auto;
        background: rgb(108, 140, 199);
        box-shadow: 2px 2px 6.1px 0px rgba(0, 0, 0, 0.25);
        display: flex;
        flex-direction: column;
        position: relative;
        position: fixed;
        top: 104px;
        left: 28%;
        // gap: 12px;
        padding: 20px;
        animation: nav 0.2s ease-in normal none 1 running;
        border-radius: 10px;

        @include oldMonitor {
            left: 23%;
        }

        @include old1024 {
            left: 18%;
        }

        .menu_link {
            color: rgb(255, 255, 255);
            @include roboto;
            font-size: 18px;
            font-weight: 400;
            line-height: 143.7%;
        }

        .menu_link_active {
            color: rgb(166, 206, 57);
            text-decoration-line: underline;
        }
    }

}

@keyframes nav {
    0% {
        height: 0;
        overflow: hidden;
    }

    100% {
        height: 200px;
        overflow: hidden;
    }
}