@import '../../../SCSS/mixins';

.icons {
    display: flex;
    flex-direction: column;
    gap: 20px;
    position: fixed;
    bottom: 20px;
    right: 2%;
    width: 40px;
    z-index: 999;

    .whatssap {
        margin-left: 25px;
        transform: translate(-50%, -50%);
        background: #20bb05;
        border-radius: 50%;
        width: 40px;
        height: 40px;
        color: #fff;
        text-align: center;
        line-height: 53px;
        font-size: 35px;
        // z-index: 999;
        margin-top: 45px;
        cursor: pointer;


        img {
            width: 100%;
            height: 100%;
        }

        &::before,
        &::after {
            content: " ";
            display: block;
            position: absolute;
            border: 50%;
            border: 1px solid #20bb05;
            left: -20px;
            right: -20px;
            top: -20px;
            bottom: -20px;
            border-radius: 50%;
            animation: animate 1.5s linear infinite;
            opacity: 0;
            backface-visibility: hidden;
        }

        &::after {
            animation-delay: .5s;
        }
    }

    @keyframes animate {
        0% {
            transform: scale(0.5);
            opacity: 0;
        }

        50% {
            opacity: 1;
        }

        100% {
            transform: scale(1.2);
            opacity: 0;
        }
    }

    .telegramm {
        margin-left: 25px;
        transform: translate(-50%, -50%);
        background: #3b84e9;
        border-radius: 50%;
        width: 40px;
        height: 40px;
        color: #fff;
        text-align: center;
        line-height: 53px;
        font-size: 35px;
        // z-index: 9999;
        margin-top: 45px;
        cursor: pointer;


        img {
            width: 100%;
            height: 100%;
        }

        &::before,
        &::after {
            content: " ";
            display: block;
            position: absolute;
            border: 50%;
            border: 1px solid #3b84e9;
            left: -20px;
            right: -20px;
            top: -20px;
            bottom: -20px;
            border-radius: 50%;
            animation: animate 1.5s linear infinite;
            opacity: 0;
            backface-visibility: hidden;
        }

        &::after {
            animation-delay: .5s;
        }
    }

    .insta {
        margin-left: 25px;
        transform: translate(-50%, -50%);
        background: #833ab4;
        background: linear-gradient(to right,
                #833ab4, #fd1d1d, #fcb045);
        border-radius: 50%;
        width: 40px;
        height: 40px;
        color: #fff;
        text-align: center;
        line-height: 53px;
        font-size: 35px;
        // z-index: 9999;
        margin-top: 45px;
        cursor: pointer;


        img {
            width: 100%;
            height: 100%;
        }

        &::before,
        &::after {
            content: " ";
            display: block;
            position: absolute;
            border: 50%;
            border: 1px solid #c90909;
            left: -20px;
            right: -20px;
            top: -20px;
            bottom: -20px;
            border-radius: 50%;
            animation: animate 1.5s linear infinite;
            opacity: 0;
            backface-visibility: hidden;
        }

        &::after {
            animation-delay: .5s;
        }
    }

    @include mobileL {
        left: 6%;
        bottom: 2%;
        gap: 10px;

        .telegramm,
        .insta,
        .whatssap {
            width: 30px;
            height: 30px;
        }
    }
}