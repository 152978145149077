@import '../../../../SCSS/mixins';

.menu_service {
  display: flex;
  gap: 55px;
  margin-bottom: 70px;

  @include old1024 {
    gap: 35px;
  }

  @include mobileL {
    flex-direction: column;
    margin-bottom: 40px;
  }

  .menu_nav_service {
    border-radius: 5px;
    background: rgb(108, 140, 199);
    min-width: 295px;
    padding: 30px 20px 30px 20px;
    min-height: 500px;

    @include old1024 {
      min-width: 200px;
    }

    @include mobileL {
      display: none;
    }

    & ul {
      display: flex;
      flex-direction: column;
      gap: 35px;

      & .menu_item,
      .active {
        color: rgb(255, 255, 255);
        @include roboto;
        font-size: 18px;
        font-weight: 400;
        line-height: 21px;
        letter-spacing: 0%;
        text-align: left;
        cursor: pointer;
      }

      & .active {
        color: rgb(166, 206, 57);
        text-decoration-line: underline;
      }
    }
  }

  .add_info,
  .up_info {
    display: flex;
    flex-direction: column;
    gap: 40px;

    & .info_card {
      display: flex;
      flex-direction: column;
      gap: 40px;

      & h2 {
        color: rgb(0, 0, 0);
        @include roboto;
        font-size: 24px;
        font-weight: 500;
        line-height: 28px;
        letter-spacing: 0%;
        text-align: left;
        white-space: break-spaces;
      }

      & p {
        color: rgb(0, 0, 0);
        @include roboto;
        font-size: 16px;
        font-weight: 400;
        line-height: 21px;
        letter-spacing: 0%;
        text-align: left;
        white-space: break-spaces;
      }
    }
  }

  & .arr_down,
  &.arr_up {
    display: none;
  }

  @include mobileL {
    .add_info {
      max-height: 200px;
      overflow: hidden;
      transition: all 0.5s;
    }

    & .arr_down {
      width: 100%;
      height: 30px;
      display: block;
      transition: all 0.3s;
    }

    & .arr_up {
      transform: rotate(180deg);
      width: 100%;
      height: 30px;
      display: block;
      transition: all 0.3s;
    }
  }
}