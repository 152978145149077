@import '../../../SCSS/mixins';

.swiper_in_service {
  display: none;

  @include mobileL {
    display: block;
  }

  .mySwiper {
    display: flex;
    justify-content: center;
    height: auto;

    & .card {
      width: 100%;
      display: flex;
      justify-content: center;
      height: auto;
    }

    & .card_swiper {
      border-radius: 20px;
      height: 350px;
      box-shadow: 1px 1px 6px 0px rgba(0, 0, 0, 0.25);
      background: rgb(254, 254, 254);
      display: flex;
      flex-direction: column;
      align-items: center;
      gap: 20px;
      padding: 20px 20px 40px;
      margin-bottom: 20px;
      height: auto;
      width: 80%;

      & img {
        width: 80px;
        height: 80px;
      }

      & .images {
        margin: 12px 0 13px;

      }

      & h3 {
        color: rgb(13, 6, 45);
        @include roboto;
        font-size: 20px;
        font-weight: 600;
        line-height: 28px;
        letter-spacing: 0%;
        text-align: center;
      }

      p {
        color: rgb(129, 129, 139);
        @include PlusJakartaSans;
        font-size: 16px;
        font-weight: 400;
        line-height: 24px;
        letter-spacing: 0%;
        text-align: center;
      }
    }

    & .card_delivery {
      flex: 0 0 80%;
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      align-items: center;
      gap: 35px;
      margin-bottom: 35px;

      @include old1024 {
        flex: 0 0 48%;
      }

      @include mobileL {
        flex: 0 0 100%;
      }

      & .delivery_steps_image {
        width: 100px;
        height: auto;
      }

      & h3 {
        // width: 200px;
        color: rgb(0, 0, 0);
        @include roboto;
        font-size: 20px;
        font-weight: 600;
        line-height: 23px;
        letter-spacing: 0%;
        text-align: center;
      }
    }

    .swiper-pagination-bullet,
    .swiper-pagination-bullet-active,
    .swiper-pagination-bullet-active-main {
      background-color: #6D6D6D;
      width: 100px;
      border-radius: 5px;
      height: 5px;
    }
  }
}