@import '../../SCSS/mixins';

.About {
    margin-top: 80px;

    @include mobileL {
        margin-top: 80px;
    }
}

.display {
    display: flex;
    padding-top: 70px;
    justify-content: space-between;

    @include mobileL {
        padding-top: 0px;
    }

    .firstDiv {
        width: 42%;
        height: 680px;
        position: relative;
        display: flex;
        justify-content: center;

        img {
            border-radius: 16px;
            width: 100%;
            height: 100%;
            object-fit: cover;
            object-position: center;
        }

        p {
            width: 90%;
            position: absolute;
            bottom: 100px;
            color: rgb(255, 255, 255);
            @include roboto;
            font-weight: 400;
            font-size: 18px;
            line-height: 21px;
            letter-spacing: 0%;
            text-align: left;
        }

        @include mobileL {
            height: 400px;
        }
    }

    .column {
        display: flex;
        flex-direction: column;
        width: 52%;
        gap: 20px;

        .secondDiv {
            width: 100%;
            height: 330px;
            position: relative;
            display: flex;
            justify-content: center;

            img {
                border-radius: 16px;
                width: 100%;
                height: 100%;
                object-fit: cover;
                object-position: center;
            }

            p {
                width: 90%;
                position: absolute;
                bottom: 100px;
                color: rgb(255, 255, 255);
                font-weight: 400;
                @include roboto;
                font-size: 18px;
                line-height: 21px;
                letter-spacing: 0%;
                text-align: left;
            }
        }
    }

    @include old1024 {
        flex-direction: column;

        .firstDiv {
            width: 100%;
            padding-bottom: 20px;
        }

        .column {
            width: 100%;
            gap: 20px;
        }
    }
}

.doverie {
    color: rgb(0, 0, 0);
    font-weight: 500;
    @include roboto;
    font-size: 48px;
    font-weight: 500;
    line-height: 56px;
    letter-spacing: 0%;
    text-align: left;
    margin-bottom: 40px;
    margin-top: 50px;

    @include mobileL {
        font-size: 32px;
        margin-bottom: 20px;
        margin-top: 30px;
    }
}

.vera {
    display: flex;
    margin-bottom: 70px;
    flex-wrap: wrap;
    justify-content: center;
    gap: 20px;

    @include mobileL {
        margin-bottom: 40px;
        justify-content: center;
    }

    img {
        object-fit: contain;
        object-position: center;
        width: 100px;
        height: 100px;

    }
}

.about {
    width: 100%;
    height: 700px;
    background: rgb(250, 250, 250);
    padding: 50px 0 0 0;
    display: flex;
    align-items: center;
    flex-direction: column;



    h2 {
        color: rgb(21, 27, 35);
        font-weight: 600;
        @include roboto;
        font-size: 48px;
        line-height: 140%;
        letter-spacing: 0%;
        text-align: center;
    }

    @include mobileL {
        padding: 0 0 0 0;

        h2 {
            font-size: 32px;
        }
    }

    p {
        width: 40%;
        color: rgb(156, 156, 156);
        font-weight: 400;
        @include roboto;
        font-size: 16px;
        line-height: 24px;
        letter-spacing: 0%;
        text-align: center;

        @include tablet {
            width: 70%;
        }

        @include mobileL {
            width: 95%;
            font-size: 14px;
        }
    }
}