@import '../../SCSS/mixins';

.home {
  @include mobileL{
    margin-bottom: 50px;
  }
}

.rewievs {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 20px;
  margin-top: 50px;

  & h2 {
    color: rgb(21, 27, 35);
    @include roboto;
    font-size: 48px;
    font-weight: 600;
    line-height: 140%;
    letter-spacing: 0%;
    text-align: center;

    @include mobileL {
      font-size: 32px;
    }
  }

  & p {
    color: rgb(156, 156, 156);
    @include PlusJakartaSans;
    font-size: 16px;
    font-weight: 400;
    line-height: 24px;
    letter-spacing: 0%;
    text-align: center;
    width: 750px;

    @include mobileL {
      width: 100%;
    }
  }
}