@mixin newMonitor1440 {
  @media screen and (max-width:1440px) {
    @content;
  }
}

@mixin roboto {
  font-family: "Roboto", sans-serif;
}

@mixin PlusJakartaSans {
  font-family: "Plus Jakarta Sans", sans-serif;
}

@mixin oldMonitorTwo {
  @media screen and (max-width: 1322px) {
    @content;
  }
}

@mixin oldMonitor {
  @media screen and (max-width: 1280px) {
    @content;
  }
}

@mixin old1024 {
  @media screen and (max-width: 1024px) {
    @content;
  }
}

@mixin tablet {
  @media screen and (max-width: 992px) {
    @content;
  }
}

@mixin mobileL {
  @media screen and (max-width: 767px) {
    @content;
  }
}

@mixin mobileM {
  @media screen and (max-width: 500px) {
    @content;
  }
}

@mixin mobileS {
  @media screen and (max-width: 430px) {
    @content;
  }
}