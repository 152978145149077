@import '../../../SCSS/mixins';

.consultation {
    margin-top: 50px;
    box-sizing: border-box;
    border-top: 1px solid rgb(0, 0, 0);

    .consultationBox {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        padding: 40px;
        gap: 24px;

        .title {
            color: rgb(19, 19, 26);
            @include roboto;
            font-size: 36px;
            font-weight: 600;
            line-height: 42px;
            letter-spacing: 0%;
        }

        .paragraf {
            color: rgb(156, 156, 156);
            @include PlusJakartaSans;
            font-size: 16px;
            font-weight: 400;
            line-height: 24px;
            letter-spacing: 0%;
        }

        .btn {
            width: max-content;
            padding: 16px 32px;
            border-radius: 100px;
            box-shadow: 24px 24px 64px 0px rgba(0, 0, 0, 0.1);
            background: rgb(108, 140, 199);
            color: rgb(238, 238, 238);
            @include PlusJakartaSans;
            font-size: 18px;
            font-weight: 600;
            line-height: 26px;
            letter-spacing: 0%;
        }
    }

    @include tablet {
        .consultationBox {
            gap: 15px;

            .title {
                font-size: 40px;
                line-height: 30px;
            }
        }
    }

    @include mobileL {
        .consultationBox {
            padding: 0px;
            margin-top: 30px;

            .title {
                font-size: 24px;
            }

            .btn {
                // width: 200px;
                padding: 12px 10px;
                font-size: 16px;
            }
        }
    }

    @include mobileS {
        .consultationBox {
            .title {
                font-size: 20px;
            }
        }
    }
}