@import '../../../SCSS/mixins';

.BurgerMenu {
    width: 90%;
    height: 100%;
    backdrop-filter: blur(20px);
    background: rgb(108, 140, 199);
    box-shadow: 2px 2px 6.1px 0px rgba(0, 0, 0, 0.25);
    display: flex;
    position: relative;
    flex-direction: column;
    align-items: flex-start;
    position: fixed;
    z-index: 1600;
    top: 0;
    right: 0;
    transition: all .5s;

    display: none;

    @include mobileL {
        display: block;
    }

    ul {
        width: 100%;
        height: 100%;
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        padding: 60px 20px;
        gap: 12px;
        overflow-y: auto;

        .menu_link {
            color: rgb(255, 255, 255);
            @include roboto;
            font-size: 20px;
            font-weight: 700;
            line-height: 143.7%;
        }

        .menu_link_active {
            color: rgb(166, 206, 57);
            text-decoration-line: underline;
        }

        .phoneNumber {
            color: rgb(255, 255, 255);
            @include roboto;
            font-size: 20px;
            font-weight: 600;
            line-height: 23.4px;
            letter-spacing: 0%;
            text-align: right;
        }

        .close_icon {
            position: absolute;
            right: 2.5%;
            top: 15px;
            color: #fff;
            font-size: 35px;
        }
    }
}

.BurgerAni {
    right: -767px;
    top: 0;
    position: fixed;
    transition: all .5s;
    z-index: 1600;
    display: none;

    @include mobileL {
        display: block;
    }
}