@import '../../../../SCSS/mixins';

.advantages {
  display: flex;
  flex-direction: column;
  gap: 70px;

  @include mobileL {
    gap: 40px;
  }

  & h2 {
    color: rgb(0, 0, 0);
    width: 550px;
    @include roboto;
    font-size: 36px;
    font-weight: 400;
    line-height: 42px;
    letter-spacing: 0%;
    text-align: left;

    @include mobileL {
      width: 100%;
      font-size: 26px;
    }
  }

  & .cards_in_advantages {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;

    & .card {
      display: flex;
      align-items: center;
      gap: 20px;
      flex: 0 0 30%;
      margin-bottom: 60px;

      @include oldMonitor {
        flex: 0 0 45%;
        margin-bottom: 30px;
      }

      @include mobileL {
        flex: 0 0 100%;
        margin-bottom: 10px;
      }

      & img {
        width: 64px;
        height: 64px;
      }

      & .text_in_card {
        display: flex;
        flex-direction: column;
        gap: 8px;

        & h3 {
          color: rgb(0, 0, 0);
          @include roboto;
          font-size: 20px;
          font-weight: 600;
          line-height: 23px;
          letter-spacing: 0%;
          text-align: left;
        }

        & p {
          color: rgb(0, 0, 0);
          @include roboto;
          font-size: 18px;
          font-weight: 400;
          line-height: 21px;
          letter-spacing: 0%;
          text-align: left;
        }
      }
    }
  }

  .about_us_in {
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 20px;

    & h2 {
      color: rgb(21, 27, 35);
      @include roboto;
      font-size: 48px;
      font-weight: 600;
      line-height: 140%;
      letter-spacing: 0%;
      text-align: center;

      @include mobileL {
        font-size: 32px;
      }
    }

    & p {
      color: rgb(156, 156, 156);
      @include PlusJakartaSans;
      font-size: 16px;
      font-weight: 400;
      line-height: 24px;
      letter-spacing: 0%;
      text-align: center;
      width: 750px;

      @include mobileL {
        width: 100%;
      }
    }
  }
}