@import '../../SCSS/mixins';

.cargo_page {
  @include mobileL {
    margin-bottom: 50px;
  }
}

.banner {
  position: relative;
  width: 100%;
  margin: 104px 0 70px;
  overflow: hidden;
  height: 480px;

  @include mobileL {
    margin: 62px 0 70px;
    height: 400px;
  }

  .background {
    position: absolute;
    width: 100%;
    height: 100%;
    object-fit: cover;
    object-position: center;
    z-index: -1;
  }

  .text_in_banner {
    border-radius: 20px;
    background: rgba(0, 0, 0, 0.2);
    width: 733px;
    height: 266px;
    margin-top: 70px;
    padding: 10px;

    & h2 {
      @include roboto;
      font-size: 68px;
      font-weight: 500;
      line-height: 72px;
      letter-spacing: 0%;
      text-align: left;
      text-transform: capitalize;
      white-space: break-spaces;
      color: rgb(255, 255, 255);

      &::first-line {
        color: rgb(166, 206, 57);
        line-height: 90px;
      }

      @include mobileL {
        width: 100%;
        font-size: 52px;
        line-height: 60px;
      }

      @include mobileM {
        width: 100%;
        font-size: 40px;
        line-height: 50px;

        &::first-line {
          color: rgb(166, 206, 57);
          line-height: 60px;
        }
      }

      @include mobileS {
        width: 100%;
        font-size: 32px;
        line-height: 60px;
      }
    }

    @include tablet {
      width: 100%;
      height: auto;
    }
  }
}

.cargo_cards {
  display: flex;
  flex-direction: column;
  gap: 70px;

  & h2 {
    color: rgb(0, 0, 0);
    @include roboto;
    font-size: 36px;
    font-weight: 600;
    line-height: 42px;
    letter-spacing: 0%;
    text-align: center;

    @include mobileL {
      font-size: 26px;
    }
  }

  @include mobileL {
    gap: 50px;

    & h2 {
      font-size: 26px;
    }
  }

  & .cards_field {
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;
  }
}

.advantages {
  display: flex;
  flex-direction: column;
  gap: 70px;

  @include mobileL {
    gap: 40px;
  }

  & h2 {
    color: rgb(0, 0, 0);
    width: 550px;
    @include roboto;
    font-size: 36px;
    font-weight: 400;
    line-height: 42px;
    letter-spacing: 0%;
    text-align: left;

    @include mobileL {
      width: 100%;
      font-size: 26px;
    }
  }

  & .cards_in_advantages {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;

    & .card {
      display: flex;
      align-items: center;
      gap: 20px;
      flex: 0 0 30%;
      margin-bottom: 60px;

      @include oldMonitor {
        flex: 0 0 45%;
        margin-bottom: 30px;
      }

      @include mobileL {
        flex: 0 0 100%;
        margin-bottom: 10px;
      }

      & img {
        width: 64px;
        height: 64px;
      }

      & .text_in_card {
        display: flex;
        flex-direction: column;
        gap: 8px;

        & h3 {
          color: rgb(0, 0, 0);
          @include roboto;
          font-size: 20px;
          font-weight: 600;
          line-height: 23px;
          letter-spacing: 0%;
          text-align: left;
        }

        & p {
          color: rgb(0, 0, 0);
          @include roboto;
          font-size: 18px;
          font-weight: 400;
          line-height: 21px;
          letter-spacing: 0%;
          text-align: left;
        }
      }
    }
  }

  .about_us_in {
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 20px;

    & h2 {
      color: rgb(21, 27, 35);
      @include roboto;
      font-size: 48px;
      font-weight: 600;
      line-height: 140%;
      letter-spacing: 0%;
      text-align: center;

      @include mobileL {
        font-size: 32px;
      }
    }

    & p {
      color: rgb(156, 156, 156);
      @include PlusJakartaSans;
      font-size: 16px;
      font-weight: 400;
      line-height: 24px;
      letter-spacing: 0%;
      text-align: center;
      width: 750px;

      @include mobileL {
        width: 100%;
      }
    }
  }
}