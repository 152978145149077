@import '../../../SCSS/mixins';

.swiper_in_our_service {
  display: none;

  @include mobileL {
    display: block;
  }

  .mySwiper {
    display: flex;
    justify-content: center;

    & .cards_in_our_service {
      display: flex;
      width: 100%;
      justify-content: center;

      & .card_in_our_service {
        border: 1px solid rgb(228, 221, 221);
        border-radius: 15px;
        padding: 20px;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        gap: 40px;
        height: 300px;
        width: 269px;


        .img {
          width: 128px;
          height: 128px;
        }

        .content {
          display: flex;
          flex-direction: column;
          gap: 20px;
          text-align: center;

          .paragraf {
            color: rgb(13, 38, 59);
            @include roboto;
            font-size: 20px;
            font-weight: 500;
            line-height: 24px;
            letter-spacing: 0%;
          }

          .line {
            width: 100%;
            text-align: center;
            border: 0.25px solid rgba(0, 0, 0, 0.5);
          }
        }
      }
    }
  }

  .swiper-pagination-bullet,
  .swiper-pagination-bullet-active,
  .swiper-pagination-bullet-active-main {
    background-color: #6D6D6D;
    width: 100px;
    border-radius: 5px;
    height: 5px;
  }
}