 @import '../../../SCSS/mixins';

 .AboutSwiper {
     width: 80%;
     height: 1100%;
     position: relative;

     @include mobileL {
         width: 100%;
     }
 }

 .swiper {
     width: 100%;
     padding-top: 50px;
     padding-bottom: 50px;

     @include mobileL {
         padding-top: 10px;
     }
 }

 .swiper-slide {
     background-position: center;
     background-size: cover;
     width: 100%;
     height: 300px;
 }

 .swiper-slide iframe {
     display: block;
     width: 100%;
     height: 100%;
     border-radius: 16px;
 }


 .iframe-container-div::after {
     content: "";
     width: 100%;
     height: 100%;
     min-height: 1px;
     position: absolute;
     top: 0;
     transition: 1s;
 }