@import '../../../../SCSS/mixins';

.Confirmation {
    width: 100%;
    height: 100%;
    position: fixed;
    top: 0;
    left: 0;
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
    z-index: 1000;
    background-color: rgba(90, 90, 90, 0.4);
    backdrop-filter: blur(5px);

    .box {
        width: 90%;
        height: calc(100vh - 100px);
        padding: 32px;
        border-radius: 46px;
        cursor: default;

        @include tablet {
            height: calc(90vh - 100px);
        }

        @include mobileL {
            width: 110%;
            height: calc(70vh - 100px);
        }

        @include mobileM {

            height: calc(55vh - 100px);
        }
    }
}