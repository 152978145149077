@import '../../SCSS/mixins';

.Galery {
    display: flex;
    width: 100%;
    flex-direction: column;
    margin-top: 104px;
    margin-bottom: 50px;

    h2 {
        color: rgb(0, 0, 0);
        @include roboto;
        font-weight: 600;
        font-size: 64px;
        line-height: 77px;
        letter-spacing: 0%;
        text-align: left;
        margin-top: 20px;
        margin-bottom: 20px;
    }

    .CardParents {
        display: flex;
        flex-wrap: wrap;
        gap: 15px;
    }

    @include mobileL {
        margin-top: 60px;

        .galeryH {
            display: none;
        }

        .CardParents {
            margin-top: 30px;
        }
    }
}

.Video {
    display: flex;
    width: 100%;
    flex-direction: column;
    align-items: center;

    .VideoH {
        color: rgb(0, 0, 0);
        font-weight: 600;
        @include roboto;
        font-size: 64px;
        line-height: 77px;
        letter-spacing: 0%;
        text-align: center;
        margin-top: 70px;
        margin-bottom: 20px;

        @include old1024 {
            font-size: 48px;
        }

        @include mobileL {
            font-size: 32px;
            margin-top: 30px;
        }
    }
}

.Card {
    display: flex;
    flex-direction: column;
    flex: 0 0 24%;
    height: 380px;
    box-sizing: border-box;
    border: 1px solid rgb(117, 117, 127);
    border-radius: 16px;

    @include oldMonitorTwo {
        flex: 0 0 32%;
    }

    @include tablet {
        flex: 0 0 47%;
    }

    @include mobileL {
        flex: 0 0 100%;
    }

    img {
        width: 100%;
        height: 300px;
        background: rgb(217, 217, 217);
        border-radius: 16px 16px 0 0;
        object-fit: cover;
        object-position: center;
        cursor: pointer;
    }

    h3 {
        color: rgb(0, 0, 0);
        font-weight: 400;
        @include roboto;
        font-size: 18px;
        line-height: 21px;
        letter-spacing: 0%;
        text-align: center;
        margin: 15px 0 10px 0;
    }

    h4 {
        color: rgb(117, 117, 127);
        font-weight: 400;
        @include roboto;
        font-size: 12px;
        line-height: 14px;
        letter-spacing: 0%;
        text-align: center;
    }
}