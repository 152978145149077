@import '../../../SCSS/mixins';

.cargo_card {
  flex: 0 1 32%;
  padding: 15px 0;
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 20px;
  box-sizing: border-box;
  border: 1px solid rgb(149, 149, 149);
  border-radius: 10px;
  margin-bottom: 40px;
  cursor: default;

  @include old1024 {
    flex: 0 1 48%;
  }

  @include mobileL {
    flex: 0 1 100%;
  }

  & .img_in_cargo_card {
    width: 70%;
    overflow: hidden;

    & img {
      width: 100%;
      height: 100%;
      object-fit: cover;
      object-position: center;
    }
  }

  & h2 {
    width: 70%;
    color: rgb(0, 0, 0);
    @include roboto;
    font-size: 24px;
    font-weight: 500;
    line-height: 28px;
    letter-spacing: 0%;
    text-align: center;
  }

  & span {
    width: 70%;
    color: rgb(0, 0, 0);
    @include roboto;
    font-size: 18px;
    font-weight: 400;
    line-height: 21px;
    letter-spacing: 0%;
    text-align: center;
  }
}