@import '../../../SCSS/mixins';

.ourService {
    margin-top: 50px;

    .title {
        display: flex;
        justify-content: center;
        color: rgb(21, 27, 35);
        @include roboto;
        font-size: 48px;
        font-weight: 600;
        line-height: 140%;
        letter-spacing: 0%;
        text-align: center;
    }

    .OurServicesBox {
        display: flex;
        flex-wrap: wrap;
        margin-top: 40px;
        gap: 1%;
        width: 100%;

        @include oldMonitor {
            // gap: 2%;
        }

        @include mobileL {
            display: none;
        }

        .card {
            border: 1px solid rgb(228, 221, 221);
            border-radius: 15px;
            padding: 20px;
            display: flex;
            flex-direction: column;
            align-items: center;
            justify-content: center;
            gap: 40px;
            height: 300px;
            // width: 269px;
            flex: 0 1 24%;
            margin-top: 30px;

            @include oldMonitor {
                flex: 0 1 32.5%;
            }

            @include old1024 {
                flex: 0 1 47%;
            }

            .img {
                width: 128px;
                height: 128px;
            }

            .content {
                display: flex;
                flex-direction: column;
                gap: 20px;
                text-align: center;
                width: 100%;

                .paragraf {
                    color: rgb(13, 38, 59);
                    @include roboto;
                    font-size: 20px;
                    font-weight: 500;
                    line-height: 24px;
                    letter-spacing: 0%;
                }

                .line {
                    width: 100%;
                    text-align: center;
                    border: 0.25px solid rgba(0, 0, 0, 0.5);
                }
            }
        }

    }

    @include tablet {
        margin-top: 30px;

        .OurServicesBox {
            margin-top: 0px;
            // justify-content: center;
            gap: 20px;
        }
    }

    @include mobileL {
        .title {
            font-size: 32px;
        }

        .OurServicesBox {
            gap: 15px;

            .card {
                padding: 15px;
                height: 200px;
                // width: 220px;
                margin-top: 10px;

                .img {
                    width: 80px;
                    height: 80px;
                }

                .content {
                    gap: 15px;

                    .paragraf {
                        font-size: 18px;
                    }
                }
            }
        }
    }

    @include mobileL {
        .title {
            font-size: 32px;
        }

        .OurServicesBox {
            gap: 15px;
            margin-top: 20px;

            .card {
                padding: 15px;
                height: 200px;
                // width: 160px;
                margin-top: 10px;
                gap: 15px;

                .img {
                    width: 80px;
                    height: 80px;
                }

                .content {
                    gap: 15px;

                    .paragraf {
                        font-size: 14px;
                    }
                }
            }
        }
    }
}