@import '../../../../SCSS/mixins';

.warehouses_in_china {
  display: flex;
  flex-direction: column;
  gap: 70px;

  @include mobileL {
    gap: 20px;
  }

  & .card_city {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;

    & .card {
      flex: 0 0 45%;
      display: flex;
      align-items: center;
      justify-content: center;
      gap: 45px;
      border-radius: 10px;
      box-shadow: 1px 1px 6px 0px rgba(0, 0, 0, 0.25);
      background: rgb(254, 254, 254);
      padding: 20px;
      margin-bottom: 35px;

      @include mobileL {
        flex: 0 0 100%;
        justify-content: left;
      }

      & .blue {
        color: rgb(108, 140, 199);
        @include roboto;
        font-size: 24px;
        font-weight: 500;
        line-height: 28px;
        letter-spacing: 0%;
        text-align: left;
      }

      & .green {
        color: rgb(166, 206, 57);
        @include roboto;
        font-size: 24px;
        font-weight: 500;
        line-height: 28px;
        letter-spacing: 0%;
        text-align: left;
      }
    }
  }
}