@import '../../../../SCSS/mixins';

.delivery {
  margin-top: 40px;
  display: flex;
  flex-direction: column;
  gap: 50px;

  h2 {
    color: rgb(0, 0, 0);
    @include roboto;
    font-size: 36px;
    font-weight: 600;
    line-height: 42px;
    letter-spacing: 0%;
    text-align: center;

    @include mobileL {
      font-size: 24px;
    }
  }

  .delivery_card {
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;

    @include mobileL {
      display: none;
    }

    & .card {
      flex: 0 0 23%;
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      align-items: center;
      gap: 35px;
      margin-bottom: 35px;

      @include old1024 {
        flex: 0 0 48%;
      }

      @include mobileL {
        flex: 0 0 100%;
      }

      & h3 {
        // width: 200px;
        color: rgb(0, 0, 0);
        @include roboto;
        font-size: 20px;
        font-weight: 600;
        line-height: 23px;
        letter-spacing: 0%;
        text-align: center;
      }
    }
  }
}