@import '../../../SCSS/mixins';

.btnWrapp {
    margin-top: 50px;

    .btnBox {
        width: 100%;
        display: flex;
        justify-content: space-between;
        align-items: center;
        border-radius: 40px;
        box-shadow: inset 0px 4px 4px 0px rgba(0, 0, 0, 0.25);
        background: rgb(255, 255, 255);
        padding: 10px 15px;
        gap: 12px;
        overflow-x: auto; // Разрешаем горизонтальную прокрутку
        // scroll-behavior: smooth; // Добавляем плавную прокрутку
        // -webkit-overflow-scrolling: touch; // Поддержка плавной прокрутки на iOS
        user-select: none;

        &::-webkit-scrollbar {
            display: none; // Скрыть стандартные полосы прокрутки
        }

        cursor: grab;

        &:active {
            cursor: grabbing;
        }

        &::-webkit-scrollbar {
            width: 10px;
            height: 8px;
        }

        &::-webkit-scrollbar-track {
            background: rgb(208, 213, 221);
            border-radius: 10px;
        }

        &::-webkit-scrollbar-thumb {
            border-radius: 4px;
            background: rgb(108, 140, 199);
            width: 3px;
        }

        .btnContent {
            display: block;
            width: 100%;
            border-radius: 40px;
            background: rgb(108, 140, 199);
            color: rgb(255, 255, 255);
            @include roboto;
            font-size: 18px;
            font-weight: 400;
            line-height: 21px;
            letter-spacing: 0%;
            padding: 17px 15px;
            white-space: nowrap;

            @include oldMonitor {
                min-width: 100%;
            }
        }
    }


    .detailBox {
        display: flex;
        flex-direction: column;
        gap: 40px;
        width: 100%;

        .btnItem {
            display: flex;
            flex-direction: column;
            gap: 20px;
            padding: 40px 32px;
            width: 70%;
            box-sizing: border-box;
            border-bottom: 1px solid rgb(163, 163, 163);

            .title {
                color: rgb(0, 0, 0);
                @include roboto;
                font-size: 32px;
                font-weight: 600;
                line-height: 30px;
                letter-spacing: 0%;
                text-align: left;
            }

            .paragraf {
                color: rgba(60, 60, 67, 0.85);
                @include PlusJakartaSans;
                font-size: 18px;
                font-weight: 400;
                line-height: 26px;
                letter-spacing: 0%;
                text-align: left;
            }
        }

        .img {
            width: 100%;
            height: 420px;
            overflow: hidden;
            margin-bottom: 60px;

            img {
                width: 100%;
                height: 100%;
                object-position: center;
                object-fit: cover;
            }
        }

        @include oldMonitor {
            .btnItem {
                width: 100%;

            }

            .img {
                height: 380px;
            }
        }

        @include tablet {
            .btnItem {
                .title {
                    font-size: 26px;
                }
            }

            .img {
                height: 300px;
            }
        }

        @include mobileL {
            gap: 20px;

            .img {
                height: 200px;
            }
        }

    }

    @include oldMonitor {
        display: flex;
        justify-content: space-between;
        gap: 20px;

        .btnBox {
            width: 600px;
            flex-direction: column;
            margin-right: 20px;
            box-shadow: inset 4px -4px 6px 2px rgba(0, 0, 0, 0.25);
            overflow-y: auto;
            overflow-x: hidden;
            max-height: 600px;
            padding: 20px;

            .btnContent {
                font-size: 16px;
                padding: 10px 10px;
            }
        }
    }

    @include mobileL {
        display: flex;
        flex-direction: column;
        align-items: center;

        .btnBox {
            flex-direction: row;
            flex-wrap: wrap;
            margin: 0;
            width: 100%;
            overflow-y: auto;
            border-radius: 10px;
            max-height: 250px;

            .btnContent {
                width: 100%;
                height: auto;
                font-size: 14px;
                padding: 12px;
                line-height: 15px;
            }
        }
    }
}