@import '../../../SCSS/mixins';

.AboutSwiper {
    width: 90%;
    height: 1000px;
    position: relative;

    & .swiper_btns {
        position: absolute;
        top: 50%;
        transform: translate(0, -50%);
        z-index: 100;
        width: 40px;
        height: 40px;
        background-color: transparent;

        &:disabled {
            opacity: 0.3;
        }

        @include mobileL {
            display: none;
        }

        & svg {
            width: 40px;
            height: 40px;
        }

        &.swiper_prev_btn {
            left: -50px;
        }

        &.swiper_next_btn {
            right: -50px;
        }
    }


}

.swiper {
    width: 100%;
    padding-top: 50px;
    padding-bottom: 50px;
    padding: 70px;

    @include mobileL {
        padding: 20px;
    }

    ;
}

.swiper-slide {
    background-position: center;
    background-size: cover;
    width: 100%;
    height: 300px;
}

.swiper-slide iframe {
    display: block;
    width: 100%;
    height: 100%;
    border-radius: 16px;
}

.iframe-container-div::after {
    content: "";
    width: 100%;
    height: 100%;
    min-height: 1px;
    position: absolute;
    top: 0;
    transition: 1s;
}

// .iframe-container-div:hover::after {
//     width: 0;
//     height: 0;
// }