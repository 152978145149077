@import '../../../SCSS/mixins';

.partner {
    margin: 60px 0;

    .title {
        color: rgb(0, 0, 0);
        @include roboto;
        font-size: 48px;
        font-weight: 500;
        line-height: 56px;
        letter-spacing: 0%;
        text-align: center;
    }

    .partnerBox {
        display: flex;
        gap: 20px;
        flex-wrap: wrap;
        justify-content: center;
        margin-top: 50px;

        .img {
            width: 160px;
            height: 50px;
            object-fit: contain;
            object-position: center;
        }

        @include mobileL {
            justify-content: center;
        }
    }

    @include tablet {
        margin-top: 30px;

        .title {
            font-size: 40px;
        }

        .partnerBox {
            margin-top: 20px;
        }
    }

    @include mobileL {
        .title {
            font-size: 32px;
        }
    }
}