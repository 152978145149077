@import '../../../../SCSS/mixins';

.panel1d-header {
    display: flex;
    justify-content: center;
    align-items: center;
}

#Atitle {
    color: rgba(60, 60, 67, 0.5);
    @include roboto;
    font-size: 32px;
    font-weight: 600;
    line-height: 40px;
    letter-spacing: 0%;
    text-align: left;
}

#Asubtitle {
    color: rgb(0, 0, 0);
    @include roboto;
    font-size: 32px;
    font-weight: 600;
    line-height: 40px;
    letter-spacing: 0%;
    text-align: left;
    margin-left: 20px;
}

@media (max-width:997px) {
    #Atitle {
        font-size: 26px;
    }

    #Asubtitle {
        font-size: 26px;
        line-height: 25px;
    }
}

@media (max-width:667px) {
    #Atitle {
        font-size: 20px;
    }

    #Asubtitle {
        font-size: 20px;
    }
}

#Aparagraf {
    color: rgba(60, 60, 67, 0.85);
    @include PlusJakartaSans;
    font-size: 18px;
    font-weight: 400;
    line-height: 26px;
    letter-spacing: 0%;
    text-align: left;
    height: auto;
    border: none;
    margin: 0;
    padding: 0;

    &::-webkit-scrollbar {
        width: 10px;
    }


    &::-webkit-scrollbar-track {
        background: #000000;
        border-radius: 2px;

    }

    &::-webkit-scrollbar-thumb {
        border-radius: 4px;
        background: rgb(108, 140, 199);
        width: 3px;
        height: 10px;
    }
}

.panel2d-header {
    display: flex;
    justify-content: center;
    align-items: center;
}

.panel3d-header {
    display: flex;
    justify-content: center;
    align-items: center;
}

.panel4d-header {
    display: flex;
    justify-content: center;
    align-items: center;
}