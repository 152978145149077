@import'../../../../SCSS/_mixins';


.south_korea {
  margin-top: 40px;
  display: flex;
  flex-direction: column;
  gap: 40px;

  & h2 {
    @include roboto;
    font-size: 36px;
    font-weight: 500;
    line-height: 28px;
    letter-spacing: 5%;
    text-align: center;
    color: rgb(0, 0, 0);

    @include mobileL {
      font-size: 24px;
    }

    & span {
      color: rgb(229, 0, 20);
    }
  }

  & h3 {
    @include roboto;
    color: rgb(0, 0, 0);
    font-size: 24px;
    font-weight: 500;
    line-height: 28px;
    letter-spacing: 0%;
    text-align: center;

    @include mobileL {
      font-size: 20px;
    }
  }

  & .cards_in_south_korea {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;

    @include mobileL {
      display: none;
    }

    & .card {
      flex: 0 0 32%;
      border-radius: 20px;
      height: 350px;
      box-shadow: 1px 1px 6px 0px rgba(0, 0, 0, 0.25);
      background: rgb(254, 254, 254);
      display: flex;
      flex-direction: column;
      align-items: center;
      gap: 20px;
      padding: 20px 20px 40px;
      margin-bottom: 20px;
      height: auto;

      @include oldMonitor {
        flex: 0 0 49%;
      }

      @include mobileL {
        flex: 0 0 100%;
      }

      & .images {
        margin: 12px 0 13px;
        width: 80px;
        height: 80px;
      }

      & h3 {
        color: rgb(13, 6, 45);
        @include roboto;
        font-size: 20px;
        font-weight: 600;
        line-height: 28px;
        letter-spacing: 0%;
        text-align: center;
      }

      p {
        color: rgb(129, 129, 139);
        @include PlusJakartaSans;
        font-size: 16px;
        font-weight: 400;
        line-height: 24px;
        letter-spacing: 0%;
        text-align: center;
      }
    }
  }

  & .card_city {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;

    & .card {
      flex: 0 0 45%;
      display: flex;
      align-items: center;
      justify-content: center;
      gap: 45px;
      border-radius: 10px;
      box-shadow: 1px 1px 6px 0px rgba(0, 0, 0, 0.25);
      background: rgb(254, 254, 254);
      padding: 20px;
      margin-bottom: 35px;

      @include mobileL {
        flex: 0 0 100%;
        justify-content: left;
        margin-bottom: 10px;
      }

      & .blue {
        color: rgb(108, 140, 199);
        @include roboto;
        font-size: 24px;
        font-weight: 500;
        line-height: 28px;
        letter-spacing: 0%;
        text-align: left;
      }

      & .green {
        color: rgb(166, 206, 57);
        @include roboto;
        font-size: 24px;
        font-weight: 500;
        line-height: 28px;
        letter-spacing: 0%;
        text-align: left;
      }
    }
  }
}