@import '../../SCSS/mixins';

footer {
    width: 100%;
    height: 480px;
    background: rgb(108, 140, 199);
    padding: 50px 0;
    margin-top: 70px;
    display: flex;
    justify-content: center;
    align-items: center;

    @include mobileL {
        margin-top: 0;
    }

    .content {
        display: flex;
        justify-content: space-between;
        gap: 40px;

        .card {
            display: flex;
            flex-direction: column;
            gap: 20px;

            &:nth-child(1) {
                width: 30%;
            }

            .menu {
                display: flex;
                flex-direction: column;
                gap: 24px;

                .nav_link {
                    color: rgb(255, 255, 255);
                    @include PlusJakartaSans;
                    font-size: 16px;
                    font-weight: 400;
                    line-height: 24px;
                    letter-spacing: 0%;
                }
            }

            .logo {
                width: 95px;
                height: 65px;
            }


            .title {
                color: rgb(255, 255, 255);
                @include roboto;
                font-size: 20px;
                font-weight: 600;
                line-height: 28px;
                letter-spacing: 0%;
            }

            .paragraf {
                color: rgb(255, 255, 255);
                @include PlusJakartaSans;
                font-size: 16px;
                font-weight: 400;
                line-height: 24px;
                letter-spacing: 0%;
            }

            .icons {
                display: flex;
                gap: 16px;

                .img {
                    width: 36px;
                    height: 36px;
                }
            }

            .bitrix_link {
                width: 200px;
                height: 40px;
                margin: 10px 0 0;

                img {
                    width: 100%;
                    height: 100%;
                    object-fit: contain;
                }

                @include mobileL {
                    width: 150px;
                }
            }
        }
    }

    @include tablet {
        padding: 30px;

        .content {
            gap: 10px;

            .card {
                .title {
                    font-size: 18px;
                }

                .paragraf {
                    font-size: 14px;
                }
            }
        }
    }

    @include mobileL {
        padding: 0;

        .content {
            flex-direction: column;
            gap: 10px;
            justify-content: center;

            .card {
                width: 100%;
                gap: 5px;
                align-items: center;

                &:nth-child(1) {
                    width: 100%;
                    text-align: center;
                }
            }
        }
    }
}