@import'../../SCSS/_mixins';

.not_found {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 70vh;

  & .box {
    max-width: 750px;
    height: 60%;
    display: flex;
    flex-direction: column;
    justify-content: space-evenly;
    align-items: center;

    & h2 {
      width: 100%;
      color: rgb(33, 33, 33);
      @include roboto;
      text-align: center;
      font-size: 94px;
      font-style: normal;
      font-weight: 400;
      line-height: 139.4%;
      letter-spacing: 0.36px;
    }

    & p {
      width: 100%;
      color: rgb(33, 33, 33);
      @include roboto;
      text-align: center;
      font-size: 24px;
      font-style: normal;
      font-weight: 400;
      line-height: 139.4%;
      letter-spacing: 0.36px;

      @include mobileL {
        font-size: 20px;
      }
    }
  }
}